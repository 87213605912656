import Router from 'next/router';

const HandleRedirects = async (userHappierLeads, afterlogin, websiteId, afterSignup) => {
  if (Object.keys(userHappierLeads).length > 0) {
    if (!userHappierLeads.ready && !afterlogin && !afterSignup) {
      if (typeof window !== 'undefined') {
        const currentWorkspace = userHappierLeads?.websites?.find((arr) => arr?._id === websiteId);
        if (!currentWorkspace) {
          localStorage.setItem('currentWebsiteId', userHappierLeads.websites[0]?._id);
          localStorage.setItem('quotaStatus', 'true');
          localStorage.setItem('prospectquotaStatus', 'true');
          if (userHappierLeads.websites[0]?._id) {
            if(userHappierLeads?.scriptInstalled || userHappierLeads?.plan == 'LTD'){
              Router.replace(`/homepage/${userHappierLeads.websites[0]?._id}`);
            }else{
              Router.replace(`/setup-tracking-script/${userHappierLeads.websites[0]?._id}`);
            }
          }
        } else {
          localStorage.setItem('currentWebsiteId', websiteId);
          localStorage.setItem('quotaStatus', 'true');
          localStorage.setItem('prospectquotaStatus', 'true');
          if (websiteId != null) {
            if(userHappierLeads?.scriptInstalled || userHappierLeads?.plan == 'LTD'){
              Router.replace(`/homepage/${websiteId}`);
            }else{
              Router.replace(`/setup-tracking-script/${websiteId}`);
            }
            
          }
        }
      }
    } else {
      if (afterlogin || afterSignup) {
        let currentWorkspace;
        if (websiteId == null && typeof window !== 'undefined') {
          localStorage.setItem('currentWebsiteId', userHappierLeads.websites[0]?._id);
          localStorage.setItem('quotaStatus', 'true');
          localStorage.setItem('prospectquotaStatus', 'true');
          currentWorkspace = userHappierLeads.websites[0];
        } else {
          currentWorkspace = userHappierLeads.websites?.find((arr) => arr?._id === websiteId);
        }

        const website_id = websiteId ?? userHappierLeads.websites[0]?._id;
        if (website_id != null) {
          await Router.prefetch(`/reveal/${website_id}`);
          await Router.prefetch(`/onboarding/${website_id}`);
          await Router.prefetch(`/homepage/${website_id}`);
          await Router.prefetch(`/prospector/${website_id}`);
          await Router.prefetch(`/list/${website_id}`);
          await Router.prefetch(`/enrich/${website_id}`);
          await Router.prefetch(`/engage/${website_id}`);
          await Router.prefetch(`/integrations/${website_id}`);
          await Router.prefetch(`/workspace-settings/${website_id}`);
        }

        if (!currentWorkspace) {
          localStorage.setItem('currentWebsiteId', website_id);
          if (userHappierLeads.id === '60b643f8a0ee660036c87d48') {
            Router.replace(`/reveal/${website_id}`);
          } else if (userHappierLeads?.ready === false && userHappierLeads.id !== '60b643f8a0ee660036c87d48') {
            Router.replace(`/onboarding/${website_id}`);
          } else {
            if(userHappierLeads?.scriptInstalled || userHappierLeads?.plan == 'LTD'){
              Router.replace(`/homepage/${website_id}`);
            }else{
              Router.replace(`/setup-tracking-script/${website_id}`);
            }            
          }
        } else {
          if (website_id != null) {
            localStorage.setItem('currentWebsiteId', website_id);
            localStorage.setItem('quotaStatus', 'true');
            localStorage.setItem('prospectquotaStatus', 'true');
            if (userHappierLeads.id === '60b643f8a0ee660036c87d48') {
              Router.replace(`/reveal/${website_id}`);
            } else {
              if (userHappierLeads?.ready == false) {
                Router.replace(`/onboarding/${website_id}`);
              } else {
                if (userHappierLeads?.plan != 'LTD') {
                  if (currentWorkspace?.verified) {
                    Router.replace(`/reveal/${website_id}`);
                  }else{
                    Router.replace(`/setup-tracking-script/${website_id}`);
                  }
                } else {   
                  if(userHappierLeads?.scriptInstalled || userHappierLeads?.plan == 'LTD'){
                    Router.replace(`/homepage/${website_id}`);
                  }else{
                    Router.replace(`/setup-tracking-script/${website_id}`);
                  }                              
                }
              }
            }
          }
        }
      }
    }
  }
};

export default HandleRedirects;
